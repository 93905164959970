// import img1 from '../images/partners/Logo.png'
import img2 from '../images/partners/Logo-1.png'
import img3 from '../images/partners/Logo-2.png'
import img4 from '../images/partners/Logo-3.png'
import img5 from '../images/partners/Logo-4.png'
// import img6 from '../images/partners/Logo-5.png'



const dataPartner = [
   
    {
        id: 2,
        img: img2
    },
    {
        id: 3,
        img: img3
    },
    {
        id: 4,
        img: img4
    },
    {
        id: 5,
        img: img5
    },
  
]

export default dataPartner;