import React, { useState } from "react";
import PropTypes from "prop-types";
import Button from "../button";
import { Link } from "react-router-dom";
import BASE_URL from "../../apiconfig";


Category.propTypes = {};

function Category(props) {
  const { data, className, onCategoryClick } = props;

  const [dataBlock] = useState({
    title: "Browse by category",
    text: "Recruitment Made Easy in 100 seconds",
  });

  return (
    <section className={className}>
      <div className="tf-container">
        <div className="row">
          <div className="col-md-12">
            <div className="tf-title">
              <div className="group-title">
                <h1>{dataBlock.title}</h1>
                <p>{dataBlock.text}</p>
              </div>
              {/* <Button title="All Categories" link="/job-grid" /> */}
            </div>
          </div>

          <div className="col-md-12">
            <div
              className="group-category-job wow fadeInUp"
              data-wow-delay=".2s"
            >
              
              {
              data.map((item) => (
                <div key={item.catname} className="job-category-box">
                  <div className="job-category-header">
                    <h5 style={{ fontSize: "16px" }}>
                      <Link
                        to="#"
                        onClick={() => onCategoryClick(item.catname)}
                      >
                        {item.catname}
                      </Link>

                     
                    </h5>
                  </div>
                  <Link to={"/job-grid/"+item.id} className="job-category-image">
                    <img
                      src={`${BASE_URL}/uploads/${item.image}`}
                      alt={item.catname}
                      className="category-image"
                    />
                  </Link>
                  {/* <Link to={"/job-grid"+} className="btn-category-job">
                    Explore Jobs{" "}
                    <span className="icon-keyboard_arrow_right"></span>
                  </Link> */}
                  {/* <Link to="#" onClick={() => onCategoryClick(item.id)}>
                        {item.catname}
                      </Link> */}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Category;