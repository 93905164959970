import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import BASE_URL from "../../apiconfig";

function ForgetPass() {
  const [formData, setFormData] = useState({
    email: '',
  });
  const navigate = useNavigate();

  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    console.log("Email before sending:", formData.email); // Log the email

    const endpoint = `${BASE_URL}/user/dashboard/forgetpasspost`;

    const formDataToSend = new FormData();
    formDataToSend.append('email', formData.email); // Append email to form data

    try {
        const response = await axios.post(endpoint, formDataToSend, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set content type to form data
            },
        });

        if (response.data.status === 'success') {
            alert(response.data.message);
            navigate('/verify-reset-code');
        } else {
            alert(response.data.message);
        }
    } catch (error) {
        console.error('Error sending reset code:', error);
        alert('Failed to send reset code.');
    }
};

  

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login tf-tab">
            <h4>Forget Password</h4>
            <div className="content-tab">
              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                  />
                </div>

                <a href="/login">Go to login page</a>
                <button type="submit">Send Code</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgetPass;
