const dataFaq = [
  {
    id: 1,
    title: "Which device can I use to enter your service ?",
    content:
      "Aenean porta, eros lacus congue lorem, samet mollis magna velit ac erat. Donec ut interdum arcu, egestas liber. Etiam condimentum aliquam accumsan. Morbi accumsan purus nec vehicula ornare.",
    show: "show",
  },
  {
    id: 2,
    title: "What’s your very first memory ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 3,
    title: "If you could visit one planet, which would it be ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 4,
    title: "What do you want to be when you grow up ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 5,
    title: "When is the last time you can remember feeling ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 6,
    title: "What’s your very first memory ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 7,
    title: "Which of the Seven Wonders of the world do you ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
  {
    id: 8,
    title: "What makes you happiest ?",
    content:
      "Once ICO period is launched, You can purchased Token with Etherum, Bitcoin or Litecoin. You can also tempor incididunt ut labore et dolore magna aliqua sed do eiusmod eaque ipsa.",
  },
];
export default dataFaq;
