import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useNavigate } from 'react-router-dom';
import BASE_URL from "../../apiconfig";


import axios from 'axios';

function SignIn() {
  const [userType, setUserType] = useState('candidate');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  
  });
  const navigate = useNavigate();


  // code starts here
  const handleSubmit = (e) => {
    e.preventDefault();
  
    const endpoint =
      userType === 'candidate'
        ? `${BASE_URL}/user/dashboard/candidateLogin`
        : `${BASE_URL}/user/dashboard/employerLogin`;
  
    const formDataEncoded = new URLSearchParams();
    formDataEncoded.append('email', formData.email);
    formDataEncoded.append('password', formData.password);
  
    axios.post(endpoint, formDataEncoded)
      .then((response) => {
        if (response.data.status === 'success') {
          
          
          if (userType === 'candidate') {
            localStorage.setItem("isLoggedIn", true);
            localStorage.setItem("email", response.data.email); // Store the email in localStorage
            navigate("/checkout"); // Redirect to home for candidates
          } else if (userType === 'employer') {
            // window.location.href = 'http://localhost/professionalplacementdesk/company/dashboard'; // Redirect to employer dashboard
            window.location.href = `${BASE_URL}/company/dashboard?email=${response.data.email}`;

          } 
          
          // Remove window.location.reload() as it won't be needed for external redirect
        } else {
          alert('Invalid credentials');
        }
      })
      .catch((error) => {
        console.error('There was an error logging in!', error);
      });
  };
  

  // code ends here
  // const handleSubmit = (e) => {
  //   e.preventDefault();
  
  //   const endpoint =
  //     userType === 'candidate'
  //       ? 'http://localhost/professionalplacementdesk/user/dashboard/candidateLogin'
  //       : 'http://localhost/professionalplacementdesk/user/dashboard/employerLogin';
  
  //   const formDataEncoded = new URLSearchParams();
  //   formDataEncoded.append('email', formData.email);
  //   formDataEncoded.append('password', formData.password);
  
  //   axios.post(endpoint, formDataEncoded)
  //     .then((response) => {
  //       if (response.data.status === 'success') {
  //         // alert('Login successful');
  //         navigate('/');

  //         localStorage.setItem('isLoggedIn', true);
  //         window.location.reload(); // refresh the page to show/hide buttons
  //       } else {
  //         alert('Invalid credentials');
  //       }
  //     })
  //     .catch((error) => {
  //       console.error('There was an error logging in!', error);
  //     });
  // };
  

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <section className="account-section">
      <div className="tf-container">
        <div className="row">
          <div className="wd-form-login tf-tab">
            <h4>Login Here</h4>

            <div className="menu-tab">
              <button
                onClick={() => setUserType("candidate")}
                style={{
                  backgroundColor: userType === "candidate" ? "#14a077" : "white",
                  color: userType === "candidate" ? "white" : "black",
                }}
              >
                Candidate
              </button>
              <button
                onClick={() => setUserType("employer")}
                style={{
                  backgroundColor: userType === "employer" ? "#14a077" : "white",
                  color: userType === "employer" ? "white" : "black",
                }}
              >
                Employer
              </button>
            </div>

            <div className="content-tab">
              <form onSubmit={handleSubmit}>
                <div className="ip">
                  <label>
                    Email<span>*</span>
                  </label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    placeholder="Email"
                    required
                  />
                </div>
                <div className="ip">
                  <label>
                    Password<span>*</span>
                  </label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    placeholder="Password"
                    required
                  />
                </div>
                <a href="/forgetpass">forget password?</a>
                <button type="submit">Login</button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SignIn;
