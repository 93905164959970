import React from "react";
import { useParams, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
// import PropTypes from "prop-types";
import dataBlog from "../assets/fakeData/dataBlog";
import Blog01 from "../components/blog/Blog01";
import Footer from "../components/footer";
import Gotop from "../components/gotop";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import MapSingle from "../components/map/MapSingle";
import lo1 from "../assets/images/logo-company/cty4.png";
import StarProgress from "../components/progressBar/StarProgress";
import { useRef, useState, useEffect } from "react";
import Video from "../components/popup/Video";
import Gallery from "../components/popup/Gallery";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import Header2 from "../components/header/Header2";
import "./jobsingle_v1.css";
import Mobile from "../components/mobile";
import BASE_URL from "../apiconfig";


Jobsingle_v1.propTypes = {};

const marKers = [
  {
    id: 1,
    title: "Rockstar Games New York",
    name: "Senior UI/UX Designer",
    address: "Las Vegas, NV 89107, USA",
    longitude: -74.00122,
    latitude: 40.71023,
    img: lo1,
  },
];

function Jobsingle_v1(props) {

  // // code starts here

  // const { jobId } = useParams();  // Capture jobId from URL
  // const [jobDetails, setJobDetails] = useState(null);
  // const [isApplied, setIsApplied] = useState(false);

  // // Get email of the job seeker from localStorage (session or cookie can be used instead)
  // const jobSeekerEmail = localStorage.getItem('email');

  // useEffect(() => {
  //   fetch(`http://localhost/professionalplacementdesk/user/dashboard/getJob/${jobId}`)
  //     .then((response) => response.json())
  //     .then((data) => setJobDetails(data))
  //     .catch((error) => console.error("Error fetching job details:", error));
  // }, [jobId]);

  // const handleApply = () => {
  //   if (!isLoggedIn) {
  //     alert("Please log in to apply.");
  //     return;
  //   }

  //   // Fetch job_seeker_id using email
  //   fetch(`http://localhost/professionalplacementdesk/user/dashboard/getJobSeekerIdByEmail`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //     body: JSON.stringify({ email: jobSeekerEmail }),
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     if (data.success) {
  //       const jobSeekerId = data.job_seeker_id;

  //       // Now send the apply request with job_seeker_id
  //       fetch(`http://localhost/professionalplacementdesk/user/dashboard/applyJob`, {
  //         method: 'POST',
  //         headers: {
  //           'Content-Type': 'application/json',
  //         },
  //         body: JSON.stringify({
  //           job_seeker_id: jobSeekerId,
  //           job_posting_id: jobId,
  //         }),
  //       })
  //       .then((response) => response.json())
  //       .then((applyData) => {
  //         if (applyData.success) {
  //           setIsApplied(true);
  //         } else {
  //           alert("Failed to apply. Try again.");
  //         }
  //       })
  //       .catch((error) => console.error("Error applying for job:", error));
  //     } else {
  //       alert("Failed to retrieve Job Seeker ID.");
  //     }
  //   })
  //   .catch((error) => console.error("Error fetching job seeker ID:", error));
  // };

  // // code ends here
  // const progressRef = useRef();
  // const [targetHeight, setTargetHeight] = useState(0);
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);

  // const [isLoggedIn, setIsLoggedIn] = useState(false);

  // useEffect(() => {
  //   const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
  //   setIsLoggedIn(loggedInStatus);
  // }, []);

  // const buttonStyle = {
  //   padding: '10px 20px',
  //   backgroundColor: isLoggedIn ? '#4CAF50' : '#f44336', // Green if logged in, red if not
  //   color: 'white',
  //   border: 'none',
  //   borderRadius: '5px',
  //   cursor: 'pointer',
  //   marginTop: '20px', // Add margin-top
  // };

  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  // useEffect(() => {
  //   if (progressRef?.current) {
  //     const offsetHeight = progressRef?.current?.offsetTop;
  //     setTargetHeight(offsetHeight);
  //   }
  // }, [progressRef]);

  // code end s here

  const { jobId } = useParams();  // Capture jobId from URL
  const navigate = useNavigate();  // Use useNavigate for navigation
  const [jobDetails, setJobDetails] = useState(null);
  const [isApplied, setIsApplied] = useState(false);
  
  // Get email of the job seeker from localStorage (session or cookie can be used instead)
  const jobSeekerEmail = localStorage.getItem('email');
  
  useEffect(() => {
    fetch(`${BASE_URL}/user/dashboard/getJob/${jobId}`)
      .then((response) => response.json())
      .then((data) => setJobDetails(data))
      .catch((error) => console.error("Error fetching job details:", error));
  }, [jobId]);
  
  const handleApply = () => {
    if (!isLoggedIn) {
      alert("Please log in to apply.");
      return;
    }

    // Fetch job_seeker_id using email
    fetch(`${BASE_URL}/user/dashboard/getJobSeekerIdByEmail`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: jobSeekerEmail }),
    })
    .then((response) => response.json())
    .then((data) => {
      if (data.success) {
        const jobSeekerId = data.job_seeker_id;

        // Now send the apply request with job_seeker_id
        fetch(`${BASE_URL}/user/dashboard/applyJob`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            job_seeker_id: jobSeekerId,
            job_posting_id: jobId,
          }),
        })
        .then((response) => response.json())
        .then((applyData) => {
          if (applyData.success) {
            setIsApplied(true);
          } else {
            alert("Failed to apply. Try again.");
          }
        })
        .catch((error) => console.error("Error applying for job:", error));
      } else {
        alert("Failed to retrieve Job Seeker ID.");
      }
    })
    .catch((error) => console.error("Error fetching job seeker ID:", error));
  };

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const loggedInStatus = localStorage.getItem('isLoggedIn') === 'true';
    setIsLoggedIn(loggedInStatus);
  }, []);

  const buttonStyle = {
    padding: '10px 20px',
    backgroundColor: isLoggedIn ? '#4CAF50' : '#f44336', // Green if logged in, red if not
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    marginTop: '20px',
  };

  // const { jobId } = useParams();  // Capture jobId from URL
  // const [jobDetails, setJobDetails] = useState(null);

  // useEffect(() => {
  //   fetch(`http://localhost/professionalplacementdesk/user/dashboard/getJob/${jobId}`)
  //     .then((response) => response.json())
  //     .then((data) => setJobDetails(data))
  //     .catch((error) => console.error("Error fetching job details:", error));
  // }, [jobId]);

  if (!jobDetails) {
    return <div>Loading...</div>;  // Loading state
  }

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">

              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item menu-item-has-children-mobile ">
                        <Link to="/">Home</Link>
                      </li> */}

                      {/* <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/aboutus">About us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-pinterest"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-youtube"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Header2 clname="actJob2" handleMobile={handleMobile} />

      {/* <section className="single-job-thumb">
        <img
          src={require("../assets/images/review/banner3.jpg")}
          alt="images"
        />
      </section> */}

      {/* <section className="form-sticky fixed-space">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-12">
              <div className="wd-job-author2">
                <div className="content-left">
                  <div className="thumb">
                    <img
                      src={require("../assets/images/logo-company/cty4.png")}
                      alt="logo"
                    />
                  </div>
                  <div className="content">
                    <Link to="#" className="category">
                      Rockstar Games New York
                    </Link>
                    <h6>
                      <Link to="#">
                        Senior UI/UX Designer{" "}
                        <span className="icon-bolt"></span>
                      </Link>
                    </h6>
                    <ul className="job-info">
                      <li>
                        <span className="icon-map-pin"></span>
                        <span>Las Vegas, NV 89107, USA</span>
                      </li>
                      <li>
                        <span className="icon-calendar"></span>
                        <span>2 days ago</span>
                      </li>
                    </ul>
                    <ul className="tags">
                      <li>
                        <Link to="#">Full-time</Link>
                      </li>
                      <li>
                        <Link to="#">Remote</Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="content-right">
                  <div className="top">
                    <Link to="#" className="share">
                      <i className="icon-share2" />
                    </Link>
                    <Link to="#" className="wishlist">
                      <i className="icon-heart" />
                    </Link>
                    <Link to="#" className="btn btn-popup">
                      <i className="icon-send" />
                      Apply Now
                    </Link>
                  </div>
                  <div className="bottom">
                    <div className="gr-rating">
                      <p>32 days left to apply</p>
                      <ul className="list-star">
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                        <li className="icon-star-full" />
                      </ul>
                    </div>
                    <div className="price">
                      <span className="icon-dollar" />
                      <p>
                        $83,000 - $110,000 <span className="year">/year</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="inner-jobs-section">
        <div className="tf-container">
          <div className="row">
            <div className="col-lg-8">
              <Tabs className="job-article tf-tab single-job">
                <div className="content-tab">
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Job Description</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                    <p className="mg-19">
                      Deloitte's Green Dot Agency is looking to add a Lead User
                      Experience Designer to our experience design team. We want
                      a passionate creative who's inspired by new trends and
                      emerging technologies, and is able to integrate them into
                      memorable user experiences. A problem solver who is
                      entrepreneurial, collaborative, hungry, and humble; can
                      deliver beautifully designed, leading-edge experiences
                      under tight deadlines; and who has demonstrated proven
                      expertise.
                    </p>
                    <h6>The Work You'll Do:</h6>
                    <ul className="list-dot">
                      <li>
                        Support the Creative Directors and Associate Creative
                        Directors of experience design to concept and oversee
                        the production of bold, innovative, award-winning
                        campaigns and digital experiences.
                      </li>
                      <li>
                        Make strategic and tactical UX decisions related to
                        design and usability as well as features and functions.
                      </li>
                      <li>
                        Creates low- and high-fidelity wireframes that represent
                        a user's journey.
                      </li>
                      <li>
                        Effectively pitch wireframes to and solutions to
                        stakeholders. You'll be the greatest advocate for our
                        work, but you'll also listen and internalize feedback so
                        that we can come back with creative that exceeds
                        expectations.
                      </li>
                    </ul>
                    <h6>What you'll bring:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Passion for Human-Centered Design-a drive to make
                        interactive technology better for people.
                      </li>
                      <li>Thorough knowledge of UX/UI best practices.</li>
                      <li>
                        Understanding of brand identity and working within a
                        defined design system as well as contributing to it.
                      </li>
                      <li>
                        A mastery of craft. You dream about color, typography,
                        and interaction design every day. You are proficient
                        using tools like Figma and Adobe XD. You can efficiently
                        use your skill set to develop new designs within
                        existing and new visual systems and design languages.
                      </li>
                      <li>
                        A portfolio which highlights strong understanding of UX
                        design including but not limited to: user flows, IA, and
                        translating customer research, analytics, and insights
                        into wireframes and high-fidelity designs.
                      </li>
                      <li>
                        Possess problem-solving skills, an investigative
                        mentality, and a proactive nature-committed to
                        delivering solutions.
                      </li>
                      <li>Possess problem-solving skills</li>
                    </ul>
                    <h6>Qualifications:</h6>
                    <ul className="list-dot mg-bt-15">
                      <li>
                        Bachelor's degree preferred, or equivalent experience.
                      </li>
                      <li>
                        At least 5-8 years of experience with UX and UI design.
                      </li>
                      <li>
                        2 years of experience with design thinking or similar
                        framework that focuses on defining users' needs early.
                      </li>
                      <li>
                        Strong portfolio showing expert concept, layout, and
                        typographic skills, as well as creativity and ability to
                        adhere to brand standards.
                      </li>
                      <li>
                        Expertise in Figma, Adobe Creative Cloud suite,
                        Microsoft suite.
                      </li>
                      <li>
                        Ability to collaborate well with cross-disciplinary
                        agency team and stakeholders at all levels.
                      </li>
                      <li>
                        Forever learning: Relentless desire to learn and
                        leverage the latest web technologies.
                      </li>
                      <li>
                        Detail-oriented: You must be highly organized, be able
                        to multi-task, and meet tight deadlines.
                      </li>
                      <li>
                        Independence: The ability to make things happen with
                        limited direction. Excellent proactive attitude,
                        take-charge personality, and "can-do" demeanor.
                      </li>
                      <li>
                        Proficiency with Front-End UI technologies a bonus but
                        not necessary (such as HTML, CSS, JavaScript).
                      </li>
                    </ul>
                    <p>
                      For individuals assigned and/or hired to work in Colorado
                      or Nevada, Deloitte is required by law to include a
                      reasonable estimate of the compensation range for this
                      role. This compensation range is specific to the State of
                      Colorado and the State of Nevada and takes into account
                      the wide range of factors that are considered in making
                      compensation decisions including but not limited to skill
                      sets; experience and training; licensure and
                      certifications; and other business and organizational
                      needs. The disclosed range estimate has not been adjusted
                      for the applicable geographic differential associated with
                      the location at which the position may be filled. At
                      Deloitte, it is not typical for an individual to be hired
                      at or near the top of the range for their role and
                      compensation decisions are dependent on the facts and
                      circumstances of each case. A reasonable estimate of the
                      current range is $86425- $177470.
                    </p>
                    <p>
                      You may also be eligible to participate in a discretionary
                      annual incentive program, subject to the rules governing
                      the program, whereby an award, if any, depends on various
                      factors, including, without limitation, individual and
                      organizational performance.
                    </p>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Job Description</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart, world-class
                      campaigns across a variety of mediums?
                    </p>
                  </TabPanel>
                  <TabPanel className="inner-content animation-tab">
                    <h5>Full Reviews</h5>
                    <p>
                      Are you a User Experience Designer with a track record of
                      delivering intuitive digital experiences that drive
                      results? Are you a strategic storyteller and systems
                      thinker who can concept and craft smart.
                    </p>
                  </TabPanel>
                </div>
              </Tabs>
            </div>
          </div>
        </div>
      </section> */}

      <section className="job-details-section mt-5 jobdetail">
        <div className="tf-container">
          <div className="row d-flex justify-content-between">
            <div className="col-lg-7 details">
              {jobDetails && (
                <>
                  <div className="company-logo p-2">
                    <img
                      src={`${BASE_URL}/uploads/${jobDetails.image}`}
                      alt={jobDetails.company_name}
                      style={{ width: "100px" }}
                    />
                  </div>
                  <h5 className="p-2">{jobDetails.title}</h5>
                  <h6 className="p-2">{jobDetails.company_name}</h6>
                  <div>
                    <p className="p-2">Location : {jobDetails.location}</p>
                    <p className="p-2">
                      Salary : {jobDetails.salarymin} - {jobDetails.salarymax} /
                      year
                    </p>
                  </div>
                  <h4 className="mt-1 ">Job Description</h4>
                  <p className="p-2 detai-content">{jobDetails.details}</p>

                  {/* <button
                    className="btn btn-popup button"
                    style={{ backgroundColor: isApplied ? "gray" : "#14a077" }}
                    onClick={handleApply}
                    disabled={isApplied}
                  >
                    {isApplied ? "Applied" : "Apply"}
                  </button> */}

                  {isLoggedIn ? (
                    <button
                      className="btn btn-popup button"
                      style={{
                        backgroundColor: isApplied ? "gray" : "#14a077",
                      }}
                      onClick={handleApply}
                      disabled={isApplied}
                    >
                      {isApplied ? "Applied" : "Apply"}
                    </button>
                  ) : (
                    <button
                      className="btn btn-popup button"
                      style={buttonStyle}
                      onClick={() => navigate("/login")} // Redirect to login page
                    >
                      Login to Apply
                    </button>
                  )}
                </>
              )}
            </div>
            <div className="col-lg-4" style={{ padding: "0" }}>
              <div
                className="cv-form-details po-sticky job-sg single-stick"
                style={{
                  background: "#fff",
                  boxShadow: "0px 0px 4px 0px rgba(0, 0, 0, 0.5)",
                }}
              >
                <div className="mt-4 mb-4">
                  <h4>Job Information</h4>
                </div>
                <ul className="list-infor">
                  {/* <li>
                    <div className="category">Website</div>
                    <div className="detail">
                      <Link to="https://themeforest.net/user/themesflat">
                        Themesflat.vn
                      </Link>
                    </div>
                  </li> */}
                  <li>
                    <div className="category">Date Posted</div>
                    <div className="detail">
                      {new Date(jobDetails.date).toLocaleDateString()}
                    </div>
                  </li>
                  <li>
                    <div className="category">Job Location</div>
                    <div className="detail">{jobDetails.location}</div>
                  </li>
                  <li>
                    <div className="category">Experience in years</div>
                    <div className="detail">{jobDetails.mine}</div>
                  </li>
                  <li>
                    <div className="category">Qualification</div>
                    <div className="detail">{jobDetails.quali}</div>
                  </li>
                  <li>
                    <div className="category">Offered Salary</div>
                    <div className="detail">{jobDetails.salarymin}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Blog01 data={dataBlog} className="inner-news-section" /> */}

      <Footer />
      <Gotop />
    </>
  );
}

export default Jobsingle_v1;
