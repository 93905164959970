import img1 from "../images/review/testimonials.jpg";

const dataTestimonials = [
  {
    id: 1,
    text: "“I found my dream job within two weeks of signing up! The platform was user-friendly, and the matching algorithm brought the perfect opportunities right to my dashboard. This portal has made job searching stress-free!”",
    avt: img1,
    name: "John D.",
    postion: "Web Developer",
  },
  {
    id: 2,
    text: "“This job portal has been a game changer for me. The application process was so seamless, and I loved how I could track the status of my applications. Highly recommend to anyone looking for a new role!”",
    avt: img1,
    name: "Sarah L.",
    postion: "Marketing Specialist",
  },
  {
    id: 3,
    text: "“We were able to find top talent quickly through this job portal. The candidate pool was diverse, and the platform made it easy to manage applications. Definitely our go-to for recruitment now!”",
    avt: img1,
    name: "Emily R.",
    postion: "HR Manager",
  },
  {
    id: 4,
    text: "“After months of frustration with other job platforms, I finally landed multiple interviews through this portal. The detailed job descriptions and employer reviews helped me make informed decisions. ”",
    avt: img1,
    name: "Michael S.",
    postion: "Software Engineer",
  },
];

export default dataTestimonials;
