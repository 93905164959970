import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Header2 from "../components/header/Header2";
import Banner04 from "../components/banner/Banner04";
// import JobStyle4 from "../components/jobs/JobStyle4";
import dataJobs from "../assets/fakeData/dataJobs";
import IconBox from "../components/iconbox";
import ReviewJob from "../components/jobs/ReviewJob";
import dataLocation from "../assets/fakeData/dataLocation";
import Category from "../components/category";
import Employer from "../components/employer";
import dataEm from "../assets/fakeData/dataEmployers";
import Partner from "../components/partner";
import dataPartner from "../assets/fakeData/dataPartner";
// import dataCate from "../assets/fakeData/dataCategory";
import Mobile from "../components/mobile";
import BASE_URL from "../apiconfig";

import Footer from "../components/footer";
// import Location04 from "../components/location/Location04";
import Gotop from "../components/gotop";
import { Link } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Collapse } from "react-collapse";
import logo from "../assets/images/logo.png";
import axios from 'axios';

Home_v4.propTypes = {};

function Home_v4(props) {
  const [toggle, setToggle] = useState({
    key: "",
    status: false,
  });
  const [isShowMobile, setShowMobile] = useState(false);
  const handleToggle = (key) => {
    if (toggle.key === key) {
      setToggle({
        status: false,
      });
    } else {
      setToggle({
        status: true,
        key,
      });
    }
  };

  const handleMobile = () => {
    const getMobile = document.querySelector(".menu-mobile-popup");
    setShowMobile(!isShowMobile);
    !isShowMobile
      ? getMobile.classList.add("modal-menu--open")
      : getMobile.classList.remove("modal-menu--open");
  };

  useEffect(() => {
    const WOW = require("wowjs");
    window.wow = new WOW.WOW({
      live: false,
    });
    window.wow.init();
  }, []);


  const [dataCate, setDataCate] = useState([]);
  const [data, setData] = useState([]); // State for job data
  const [companyId, setCompanyId] = useState("");


  // Fetch categories from backend when component mounts
  useEffect(() => {
    console.log("here is baseurl" , BASE_URL);
    axios.get(`${BASE_URL}/user/dashboard/getcategories`)
      .then(response => {
        console.log("hello world"+JSON.stringify(response, null, 4));  // Inspect the response data structure
        setDataCate(response.data);  // Update the state with the fetched data
      })
      .catch(error => {
        console.error('There was an error fetching the categories!', error);
      });
  }, []);

  const handleCategoryClick = (catname) => {
    axios
      .get(`${BASE_URL}/user/dashboard/getjobs?category=${catname}`)
      .then((response) => {
        console.log("this is data"+response)
        setData(response.data); 
      })
      .catch((error) => {
        console.error('Error fetching jobs for category:', error);
      });
  };

  
  // code for category ends heere

  const handleCompanyChange = (event) => {
    setCompanyId(event.target.value);
    fetchJobs(null, event.target.value); // Fetch jobs based on selected company
  };

  const fetchJobs = (catname, company_id) => {
    let url = `${BASE_URL}/user/dashboard/getjobs?`;
    if (catname) url += `category=${catname}&`;
    if (company_id) url += `company_id=${company_id}`;

    axios
      .get(url)
      .then((response) => {
        setData(response.data);
        console.log("Jobs fetched:", response.data);
      })
      .catch((error) => {
        console.error("Error fetching jobs:", error);
      });
  };

  // code for employer starts here
  const [dataEm, setDataEm] = useState([]);

  useEffect(() => {
    fetch(`${BASE_URL}/user/dashboard/getcompanies`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data); // Add this line to inspect the data
        setDataEm(data);
      })
      .catch((error) => console.error('Error fetching company data:', error));
  }, []);

  // code for eployer ends here

  return (
    <>
      <div className="menu-mobile-popup">
        <div className="modal-menu__backdrop" onClick={handleMobile}></div>
        <div className="widget-filter">
          <div className="mobile-header">
            <div id="logo" className="logo">
              <Link to="/">
                <img className="site-logo" src={logo} alt="Image" />
              </Link>
            </div>
            <Link className="title-button-group" onClick={handleMobile}>
              <i className="icon-close"></i>
            </Link>
          </div>

          <Tabs className="tf-tab">
            <TabList className="menu-tab">
              <Tab className="user-tag">Menu</Tab>
              {/* <Tab className="user-tag">Categories</Tab> */}
            </TabList>

            <div className="content-tab">
              <TabPanel className="header-ct-center menu-moblie animation-tab">
                <div className="nav-wrap">
                  <nav className="main-nav mobile">
                    <ul id="menu-primary-menu" className="menu">
                      {/* <li className="menu-item menu-item-has-children-mobile ">
                        <Link to="/">Home</Link>
                      </li> */}

                      {/* <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/aboutus">About us</Link>
                      </li> */}
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/job-grid">Browse jobs </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/employers_v3">Companies </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/pricing">Pricing </Link>
                      </li>
                      <li className="menu-item menu-item-has-children-mobile">
                        <Link to="/faqs">Services </Link>
                      </li>
                    </ul>
                  </nav>
                </div>
              </TabPanel>

              
            </div>
          </Tabs>

          <div className="header-customize-item button">
            {/* <Link to="/">Upload Resume</Link> */}
            <Mobile />
          </div>

          <div className="mobile-footer">
            <div className="icon-infor d-flex aln-center">
              <div className="icon">
                <span className="icon-call-calling">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                </span>
              </div>
              <div className="content">
                <p>Need help? 24/7</p>
                <h6>
                  <Link to="tel:9811226910">9811226910</Link>
                </h6>
              </div>
            </div>
            <div className="wd-social d-flex aln-center">
              <ul className="list-social d-flex aln-center">
                <li>
                  <Link to="#">
                    <i className="icon-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-linkedin2"></i>
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="icon-twitter"></i>
                  </Link>
                </li>

                <li>
                  <Link to="#">
                    <i className="icon-instagram1"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <Header2 clname="act1" handleMobile={handleMobile} />

      <Banner04 />

      <Category
        data={dataCate}
        className="job-category-section"
        onCategoryClick={handleCategoryClick}
      />

      {/* <JobStyle4 data={dataJobs} className="jobs-section-two" /> */}

      <IconBox />

      <ReviewJob />

      {/* <Location04
        data={dataLocation}
        className="location-section-two background1"
      /> */}

      {/* <Employer data={dataEm} /> */}
      <Partner data={dataPartner} />
      <Footer />
      <Gotop />
    </>
  );
}

export default Home_v4;
