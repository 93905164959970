import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import BASE_URL from "../../apiconfig";

import axios from "axios";

function SignUp() {
  const [userType, setUserType] = useState("candidate");
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",

    country: "", // For employers only
  });

  const [isPaymentStep, setIsPaymentStep] = useState(false); // Add payment step state
  const [paymentData, setPaymentData] = useState({}); // Store payment response

  const navigate = useNavigate();
  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const endpoint =
      userType === 'candidate' ? `${BASE_URL}/user/dashboard/savecandidate` : `${BASE_URL}/user/dashboard/saveemployer`;

    const formDataEncoded = new URLSearchParams();
    formDataEncoded.append('name', formData.name);
    formDataEncoded.append('mobile', formData.mobile);
    formDataEncoded.append('email', formData.email);
    formDataEncoded.append('password', formData.password);

    if (userType === 'employer') {
      formDataEncoded.append('country', formData.country); // For employers only
    }

    axios.post(endpoint, formDataEncoded)
      .then((response) => {
        alert('User registered succesfully');
        navigate('/login');
      })
      .catch((error) => {
        console.error('There was an error submitting the form!', error);
      });
  };

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   if (userType === "employer" && !isPaymentStep) {
  //     // First step: initiate payment
  //     const amountFormatted = parseFloat(formData.amount || 1).toFixed(2);

  //       axios.post("http://localhost/professionalplacementdesk/user/dashboard/paymentRequest", {
  //         ...formData,
  //         amount: amountFormatted
  //     })
  //     .then(response => {
  //         setPaymentData(response.data);
  //         setIsPaymentStep(true);
  //     })
  //     .catch(error => console.error("Error initiating payment", error));
      
  //   } else if (userType === "employer" && isPaymentStep) {
  //     // Submit form after payment
  //     axios
  //       .post(
  //         "http://localhost/professionalplacementdesk/user/dashboard/saveEmployer",
  //         formData
  //       )
  //       .then(() => {
  //         alert("Employer registered successfully");
  //         navigate("/login");
  //       })
  //       .catch((error) => console.error("Error registering employer", error));
  //   } else {
  //     // Candidate registration
  //     axios
  //       .post(
  //         "http://localhost/professionalplacementdesk/user/dashboard/saveCandidate",
  //         formData
  //       )
  //       .then(() => {
  //         alert("Candidate registered successfully");
  //         navigate("/login");
  //       })
  //       .catch((error) => console.error("Error registering candidate", error));
  //   }
  // };

    return (
      <section className="account-section">
        <div className="tf-container">
          <div className="row">
            <div className="wd-form-login tf-tab">
              <h4>Sign Up Here</h4>
              {/* <div className="menu-tab">
                <button onClick={() => setUserType("candidate")}>
                  Candidate
                </button>
                <button onClick={() => setUserType("employer")}>Employer</button>
              </div> */}

              <div className="menu-tab">
                <button
                  onClick={() => setUserType("candidate")}
                  style={{
                    backgroundColor: userType === "candidate" ? "#14a077" : "white",
                    color: userType === "candidate" ? "white" : "black",
                  }}
                >
                  Candidate
                </button>
                <button
                  onClick={() => setUserType("employer")}
                  style={{
                    backgroundColor: userType === "employer" ? "#14a077" : "white",
                    color: userType === "employer" ? "white" : "black",
                  }}
                >
                  Employer
                </button>
              </div>
              <div className="content-tab">
                <form onSubmit={handleSubmit}>
                  <div className="ip">
                    <label>
                      Name<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                      placeholder="Name"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Mobile<span>*</span>
                    </label>
                    <input
                      type="text"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                      placeholder="Mobile"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Email<span>*</span>
                    </label>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      placeholder="Email"
                      required
                    />
                  </div>
                  <div className="ip">
                    <label>
                      Password<span>*</span>
                    </label>
                    <input
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleInputChange}
                      placeholder="Password"
                      required
                    />
                  </div>
                  {/* <div className="ip">
                    <label>
                      Confirm Password<span>*</span>
                    </label>
                    <input
                      type="password"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleInputChange}
                      placeholder="Confirm Password"
                      required
                    />
                  </div> */}

                  {userType === "employer" && (
                    <div className="ip">
                      <label>
                        Country<span>*</span>
                      </label>
                      <select
                        name="country"
                        value={formData.country}
                        onChange={handleInputChange}
                        className="form-control"
                        required
                      >
                        <option value="">Select Country</option>
                        <option value="Afghanistan">Afghanistan</option>
                        <option value="Åland Islands">Åland Islands</option>
                        <option value="Albania">Albania</option>
                        <option value="Algeria">Algeria</option>
                        <option value="American Samoa">American Samoa</option>
                        <option value="Andorra">Andorra</option>
                        <option value="Angola">Angola</option>
                        <option value="Anguilla">Anguilla</option>
                        <option value="Antarctica">Antarctica</option>
                        <option value="Antigua and Barbuda">
                          Antigua and Barbuda
                        </option>
                        <option value="Argentina">Argentina</option>
                        <option value="Armenia">Armenia</option>
                        <option value="Aruba">Aruba</option>
                        <option value="Australia">Australia</option>
                        <option value="Austria">Austria</option>
                        <option value="Azerbaijan">Azerbaijan</option>
                        <option value="Bahamas">Bahamas</option>
                        <option value="Bahrain">Bahrain</option>
                        <option value="Bangladesh">Bangladesh</option>
                        <option value="Barbados">Barbados</option>
                        <option value="Belarus">Belarus</option>
                        <option value="Belgium">Belgium</option>
                        <option value="Belize">Belize</option>
                        <option value="Benin">Benin</option>
                        <option value="Bermuda">Bermuda</option>
                        <option value="Bhutan">Bhutan</option>
                        <option value="Bolivia">Bolivia</option>
                        <option value="Bosnia and Herzegovina">
                          Bosnia and Herzegovina
                        </option>
                        <option value="Botswana">Botswana</option>
                        <option value="Bouvet Island">Bouvet Island</option>
                        <option value="Brazil">Brazil</option>
                        <option value="British Indian Ocean Territory">
                          British Indian Ocean Territory
                        </option>
                        <option value="Brunei Darussalam">
                          Brunei Darussalam
                        </option>
                        <option value="Bulgaria">Bulgaria</option>
                        <option value="Burkina Faso">Burkina Faso</option>
                        <option value="Burundi">Burundi</option>
                        <option value="Cambodia">Cambodia</option>
                        <option value="Cameroon">Cameroon</option>
                        <option value="Canada">Canada</option>
                        <option value="Cape Verde">Cape Verde</option>
                        <option value="Cayman Islands">Cayman Islands</option>
                        <option value="Central African Republic">
                          Central African Republic
                        </option>
                        <option value="Chad">Chad</option>
                        <option value="Chile">Chile</option>
                        <option value="China">China</option>
                        <option value="Christmas Island">Christmas Island</option>
                        <option value="Cocos (Keeling) Islands">
                          Cocos (Keeling) Islands
                        </option>
                        <option value="Colombia">Colombia</option>
                        <option value="Comoros">Comoros</option>
                        <option value="Congo">Congo</option>
                        <option value="Congo, The Democratic Republic of The">
                          Congo, The Democratic Republic of The
                        </option>
                        <option value="Cook Islands">Cook Islands</option>
                        <option value="Costa Rica">Costa Rica</option>
                        <option value="Cote D'ivoire">Cote D'ivoire</option>
                        <option value="Croatia">Croatia</option>
                        <option value="Cuba">Cuba</option>
                        <option value="Cyprus">Cyprus</option>
                        <option value="Czech Republic">Czech Republic</option>
                        <option value="Denmark">Denmark</option>
                        <option value="Djibouti">Djibouti</option>
                        <option value="Dominica">Dominica</option>
                        <option value="Dominican Republic">
                          Dominican Republic
                        </option>
                        <option value="Ecuador">Ecuador</option>
                        <option value="Egypt">Egypt</option>
                        <option value="El Salvador">El Salvador</option>
                        <option value="Equatorial Guinea">
                          Equatorial Guinea
                        </option>
                        <option value="Eritrea">Eritrea</option>
                        <option value="Estonia">Estonia</option>
                        <option value="Ethiopia">Ethiopia</option>
                        <option value="Falkland Islands (Malvinas)">
                          Falkland Islands (Malvinas)
                        </option>
                        <option value="Faroe Islands">Faroe Islands</option>
                        <option value="Fiji">Fiji</option>
                        <option value="Finland">Finland</option>
                        <option value="France">France</option>
                        <option value="French Guiana">French Guiana</option>
                        <option value="French Polynesia">French Polynesia</option>
                        <option value="French Southern Territories">
                          French Southern Territories
                        </option>
                        <option value="Gabon">Gabon</option>
                        <option value="Gambia">Gambia</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Germany">Germany</option>
                        <option value="Ghana">Ghana</option>
                        <option value="Gibraltar">Gibraltar</option>
                        <option value="Greece">Greece</option>
                        <option value="Greenland">Greenland</option>
                        <option value="Grenada">Grenada</option>
                        <option value="Guadeloupe">Guadeloupe</option>
                        <option value="Guam">Guam</option>
                        <option value="Guatemala">Guatemala</option>
                        <option value="Guernsey">Guernsey</option>
                        <option value="Guinea">Guinea</option>
                        <option value="Guinea-bissau">Guinea-bissau</option>
                        <option value="Guyana">Guyana</option>
                        <option value="Haiti">Haiti</option>
                        <option value="Heard Island and Mcdonald Islands">
                          Heard Island and Mcdonald Islands
                        </option>
                        <option value="Holy See (Vatican City State)">
                          Holy See (Vatican City State)
                        </option>
                        <option value="Honduras">Honduras</option>
                        <option value="Hong Kong">Hong Kong</option>
                        <option value="Hungary">Hungary</option>
                        <option value="Iceland">Iceland</option>
                        <option value="India">India</option>
                        <option value="Indonesia">Indonesia</option>
                        <option value="Iran, Islamic Republic of">
                          Iran, Islamic Republic of
                        </option>
                        <option value="Iraq">Iraq</option>
                        <option value="Ireland">Ireland</option>
                        <option value="Isle of Man">Isle of Man</option>
                        <option value="Israel">Israel</option>
                        <option value="Italy">Italy</option>
                        <option value="Jamaica">Jamaica</option>
                        <option value="Japan">Japan</option>
                        <option value="Jersey">Jersey</option>
                        <option value="Jordan">Jordan</option>
                        <option value="Kazakhstan">Kazakhstan</option>
                        <option value="Kenya">Kenya</option>
                        <option value="Kiribati">Kiribati</option>
                        <option value="Korea, Democratic People's Republic of">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="Korea, Republic of">
                          Korea, Republic of
                        </option>
                        <option value="Kuwait">Kuwait</option>
                        <option value="Kyrgyzstan">Kyrgyzstan</option>
                        <option value="Lao People's Democratic Republic">
                          Lao People's Democratic Republic
                        </option>
                        <option value="Latvia">Latvia</option>
                        <option value="Lebanon">Lebanon</option>
                        <option value="Lesotho">Lesotho</option>
                        <option value="Liberia">Liberia</option>
                        <option value="Libyan Arab Jamahiriya">
                          Libyan Arab Jamahiriya
                        </option>
                        <option value="Liechtenstein">Liechtenstein</option>
                        <option value="Lithuania">Lithuania</option>
                        <option value="Luxembourg">Luxembourg</option>
                        <option value="Macao">Macao</option>
                        <option value="Macedonia, The Former Yugoslav Republic of">
                          Macedonia, The Former Yugoslav Republic of
                        </option>
                        <option value="Madagascar">Madagascar</option>
                        <option value="Malawi">Malawi</option>
                        <option value="Malaysia">Malaysia</option>
                        <option value="Maldives">Maldives</option>
                        <option value="Mali">Mali</option>
                        <option value="Malta">Malta</option>
                        <option value="Marshall Islands">Marshall Islands</option>
                        <option value="Martinique">Martinique</option>
                        <option value="Mauritania">Mauritania</option>
                        <option value="Mauritius">Mauritius</option>
                        <option value="Mayotte">Mayotte</option>
                        <option value="Mexico">Mexico</option>
                        <option value="Micronesia, Federated States of">
                          Micronesia, Federated States of
                        </option>
                        <option value="Moldova, Republic of">
                          Moldova, Republic of
                        </option>
                        <option value="Monaco">Monaco</option>
                        <option value="Mongolia">Mongolia</option>
                        <option value="Montenegro">Montenegro</option>
                        <option value="Montserrat">Montserrat</option>
                        <option value="Morocco">Morocco</option>
                        <option value="Mozambique">Mozambique</option>
                        <option value="Myanmar">Myanmar</option>
                        <option value="Namibia">Namibia</option>
                        <option value="Nauru">Nauru</option>
                        <option value="Nepal">Nepal</option>
                        <option value="Netherlands">Netherlands</option>
                        <option value="New Caledonia">New Caledonia</option>
                        <option value="New Zealand">New Zealand</option>
                        <option value="Nicaragua">Nicaragua</option>
                        <option value="Niger">Niger</option>
                        <option value="Nigeria">Nigeria</option>
                        <option value="Niue">Niue</option>
                        <option value="Norfolk Island">Norfolk Island</option>
                        <option value="Northern Mariana Islands">
                          Northern Mariana Islands
                        </option>
                        <option value="Norway">Norway</option>
                        <option value="Oman">Oman</option>
                        <option value="Pakistan">Pakistan</option>
                        <option value="Palau">Palau</option>
                        <option value="Palestinian Territory, Occupied">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="Panama">Panama</option>
                        <option value="Papua New Guinea">Papua New Guinea</option>
                        <option value="Paraguay">Paraguay</option>
                        <option value="Peru">Peru</option>
                        <option value="Philippines">Philippines</option>
                        <option value="Pitcairn">Pitcairn</option>
                        <option value="Poland">Poland</option>
                        <option value="Portugal">Portugal</option>
                        <option value="Puerto Rico">Puerto Rico</option>
                        <option value="Qatar">Qatar</option>
                        <option value="Reunion">Reunion</option>
                        <option value="Romania">Romania</option>
                        <option value="Russian Federation">
                          Russian Federation
                        </option>
                        <option value="Rwanda">Rwanda</option>
                        <option value="Saint Helena">Saint Helena</option>
                        <option value="Saint Kitts and Nevis">
                          Saint Kitts and Nevis
                        </option>
                        <option value="Saint Lucia">Saint Lucia</option>
                        <option value="Saint Pierre and Miquelon">
                          Saint Pierre and Miquelon
                        </option>
                        <option value="Saint Vincent and The Grenadines">
                          Saint Vincent and The Grenadines
                        </option>
                        <option value="Samoa">Samoa</option>
                        <option value="San Marino">San Marino</option>
                        <option value="Sao Tome and Principe">
                          Sao Tome and Principe
                        </option>
                        <option value="Saudi Arabia">Saudi Arabia</option>
                        <option value="Senegal">Senegal</option>
                        <option value="Serbia">Serbia</option>
                        <option value="Seychelles">Seychelles</option>
                        <option value="Sierra Leone">Sierra Leone</option>
                        <option value="Singapore">Singapore</option>
                        <option value="Slovakia">Slovakia</option>
                        <option value="Slovenia">Slovenia</option>
                        <option value="Solomon Islands">Solomon Islands</option>
                        <option value="Somalia">Somalia</option>
                        <option value="South Africa">South Africa</option>
                        <option value="South Georgia and The South Sandwich Islands">
                          South Georgia and The South Sandwich Islands
                        </option>
                        <option value="Spain">Spain</option>
                        <option value="Sri Lanka">Sri Lanka</option>
                        <option value="Sudan">Sudan</option>
                        <option value="Suriname">Suriname</option>
                        <option value="Svalbard and Jan Mayen">
                          Svalbard and Jan Mayen
                        </option>
                        <option value="Swaziland">Swaziland</option>
                        <option value="Sweden">Sweden</option>
                        <option value="Switzerland">Switzerland</option>
                        <option value="Syrian Arab Republic">
                          Syrian Arab Republic
                        </option>
                        <option value="Taiwan, Province of China">
                          Taiwan, Province of China
                        </option>
                        <option value="Tajikistan">Tajikistan</option>
                        <option value="Tanzania, United Republic of">
                          Tanzania, United Republic of
                        </option>
                        <option value="Thailand">Thailand</option>
                        <option value="Timor-leste">Timor-leste</option>
                        <option value="Togo">Togo</option>
                        <option value="Tokelau">Tokelau</option>
                        <option value="Tonga">Tonga</option>
                        <option value="Trinidad and Tobago">
                          Trinidad and Tobago
                        </option>
                        <option value="Tunisia">Tunisia</option>
                        <option value="Turkey">Turkey</option>
                        <option value="Turkmenistan">Turkmenistan</option>
                        <option value="Turks and Caicos Islands">
                          Turks and Caicos Islands
                        </option>
                        <option value="Tuvalu">Tuvalu</option>
                        <option value="Uganda">Uganda</option>
                        <option value="Ukraine">Ukraine</option>
                        <option value="United Arab Emirates">
                          United Arab Emirates
                        </option>
                        <option value="United Kingdom">United Kingdom</option>
                        <option value="United States">United States</option>
                        <option value="Uruguay">Uruguay</option>
                        <option value="Uzbekistan">Uzbekistan</option>
                        <option value="Vanuatu">Vanuatu</option>
                        <option value="Venezuela">Venezuela</option>
                        <option value="Viet Nam">Viet Nam</option>
                        <option value="Western Sahara">Western Sahara</option>
                        <option value="Yemen">Yemen</option>
                        <option value="Zambia">Zambia</option>
                        <option value="Zimbabwe">Zimbabwe</option>
                      </select>
                    </div>
                     )}

  {formData.country === "India" && (
    <div className="ip">
      <label>
        Gst no.<span>*</span>
      </label>
      <input
        type="text"
        name="gst"
        value={formData.gst}
        onChange={handleInputChange}
        placeholder="gst number"
      />
    </div>
  )}

                  <button type="submit">Register</button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );

//   return (
//     <section className="account-section">
//       <div className="tf-container">
//         <div className="row">
//           <div className="wd-form-login tf-tab">
//             <h4>Sign Up Here</h4>
//             <div className="menu-tab">
//               <button
//                 onClick={() => setUserType("candidate")}
//                 style={{
//                   backgroundColor:
//                     userType === "candidate" ? "#14a077" : "white",
//                   color: userType === "candidate" ? "white" : "black",
//                 }}
//               >
//                 Candidate
//               </button>
//               <button
//                 onClick={() => setUserType("employer")}
//                 style={{
//                   backgroundColor:
//                     userType === "employer" ? "#14a077" : "white",
//                   color: userType === "employer" ? "white" : "black",
//                 }}
//               >
//                 Employer
//               </button>
//             </div>
//             <div className="content-tab">
//               {!isPaymentStep ? (
//                 <form onSubmit={handleSubmit}>
//                   {/* Employer/Candidate form */}
//                   <div className="ip">
//                     <label>
//                       Name<span>*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="name"
//                       value={formData.name}
//                       onChange={handleInputChange}
//                       placeholder="Name"
//                       required
//                     />
//                   </div>
//                   {/* Other fields */}
//                   <div className="ip">
//                     <label>
//                       Mobile<span>*</span>
//                     </label>
//                     <input
//                       type="text"
//                       name="mobile"
//                       value={formData.mobile}
//                       onChange={handleInputChange}
//                       placeholder="Mobile"
//                       required
//                     />
//                   </div>
//                   <div className="ip">
//                     <label>
//                       Email<span>*</span>
//                     </label>
//                     <input
//                       type="email"
//                       name="email"
//                       value={formData.email}
//                       onChange={handleInputChange}
//                       placeholder="Email"
//                       required
//                     />
//                   </div>
//                   <div className="ip">
//                     <label>
//                       Password<span>*</span>
//                     </label>
//                     <input
//                       type="password"
//                       name="password"
//                       value={formData.password}
//                       onChange={handleInputChange}
//                       placeholder="Password"
//                       required
//                     />
//                   </div>
//                   {userType === "employer" && (
//                     <div className="ip">
//                       <label>
//                         Country<span>*</span>
//                       </label>
//                       <select
//                         name="country"
//                         value={formData.country}
//                         onChange={handleInputChange}
//                         required
//                       >
//                         {/* Add country options */}
//                         <option value="">Select Country</option>
//                         <option value="Afghanistan">Afghanistan</option>
//                         <option value="Åland Islands">Åland Islands</option>
//                         <option value="Albania">Albania</option>
//                         <option value="Algeria">Algeria</option>
//                         <option value="American Samoa">American Samoa</option>
//                         <option value="Andorra">Andorra</option>
//                         <option value="Angola">Angola</option>
//                         <option value="Anguilla">Anguilla</option>
//                         <option value="Antarctica">Antarctica</option>
//                         <option value="Antigua and Barbuda">
//                           Antigua and Barbuda
//                         </option>
//                         <option value="Argentina">Argentina</option>
//                         <option value="Armenia">Armenia</option>
//                         <option value="Aruba">Aruba</option>
//                         <option value="Australia">Australia</option>
//                         <option value="Austria">Austria</option>
//                         <option value="Azerbaijan">Azerbaijan</option>
//                         <option value="Bahamas">Bahamas</option>
//                         <option value="Bahrain">Bahrain</option>
//                         <option value="Bangladesh">Bangladesh</option>
//                         <option value="Barbados">Barbados</option>
//                         <option value="Belarus">Belarus</option>
//                         <option value="Belgium">Belgium</option>
//                         <option value="Belize">Belize</option>
//                         <option value="Benin">Benin</option>
//                         <option value="Bermuda">Bermuda</option>
//                         <option value="Bhutan">Bhutan</option>
//                         <option value="Bolivia">Bolivia</option>
//                         <option value="Bosnia and Herzegovina">
//                           Bosnia and Herzegovina
//                         </option>
//                         <option value="Botswana">Botswana</option>
//                         <option value="Bouvet Island">Bouvet Island</option>
//                         <option value="Brazil">Brazil</option>
//                         <option value="British Indian Ocean Territory">
//                           British Indian Ocean Territory
//                         </option>
//                         <option value="Brunei Darussalam">
//                           Brunei Darussalam
//                         </option>
//                         <option value="Bulgaria">Bulgaria</option>
//                         <option value="Burkina Faso">Burkina Faso</option>
//                         <option value="Burundi">Burundi</option>
//                         <option value="Cambodia">Cambodia</option>
//                         <option value="Cameroon">Cameroon</option>
//                         <option value="Canada">Canada</option>
//                         <option value="Cape Verde">Cape Verde</option>
//                         <option value="Cayman Islands">Cayman Islands</option>
//                         <option value="Central African Republic">
//                           Central African Republic
//                         </option>
//                         <option value="Chad">Chad</option>
//                         <option value="Chile">Chile</option>
//                         <option value="China">China</option>
//                         <option value="Christmas Island">
//                           Christmas Island
//                         </option>
//                         <option value="Cocos (Keeling) Islands">
//                           Cocos (Keeling) Islands
//                         </option>
//                         <option value="Colombia">Colombia</option>
//                         <option value="Comoros">Comoros</option>
//                         <option value="Congo">Congo</option>
//                         <option value="Congo, The Democratic Republic of The">
//                           Congo, The Democratic Republic of The
//                         </option>
//                         <option value="Cook Islands">Cook Islands</option>
//                         <option value="Costa Rica">Costa Rica</option>
//                         <option value="Cote D'ivoire">Cote D'ivoire</option>
//                         <option value="Croatia">Croatia</option>
//                         <option value="Cuba">Cuba</option>
//                         <option value="Cyprus">Cyprus</option>
//                         <option value="Czech Republic">Czech Republic</option>
//                         <option value="Denmark">Denmark</option>
//                         <option value="Djibouti">Djibouti</option>
//                         <option value="Dominica">Dominica</option>
//                         <option value="Dominican Republic">
//                           Dominican Republic
//                         </option>
//                         <option value="Ecuador">Ecuador</option>
//                         <option value="Egypt">Egypt</option>
//                         <option value="El Salvador">El Salvador</option>
//                         <option value="Equatorial Guinea">
//                           Equatorial Guinea
//                         </option>
//                         <option value="Eritrea">Eritrea</option>
//                         <option value="Estonia">Estonia</option>
//                         <option value="Ethiopia">Ethiopia</option>
//                         <option value="Falkland Islands (Malvinas)">
//                           Falkland Islands (Malvinas)
//                         </option>
//                         <option value="Faroe Islands">Faroe Islands</option>
//                         <option value="Fiji">Fiji</option>
//                         <option value="Finland">Finland</option>
//                         <option value="France">France</option>
//                         <option value="French Guiana">French Guiana</option>
//                         <option value="French Polynesia">
//                           French Polynesia
//                         </option>
//                         <option value="French Southern Territories">
//                           French Southern Territories
//                         </option>
//                         <option value="Gabon">Gabon</option>
//                         <option value="Gambia">Gambia</option>
//                         <option value="Georgia">Georgia</option>
//                         <option value="Germany">Germany</option>
//                         <option value="Ghana">Ghana</option>
//                         <option value="Gibraltar">Gibraltar</option>
//                         <option value="Greece">Greece</option>
//                         <option value="Greenland">Greenland</option>
//                         <option value="Grenada">Grenada</option>
//                         <option value="Guadeloupe">Guadeloupe</option>
//                         <option value="Guam">Guam</option>
//                         <option value="Guatemala">Guatemala</option>
//                         <option value="Guernsey">Guernsey</option>
//                         <option value="Guinea">Guinea</option>
//                         <option value="Guinea-bissau">Guinea-bissau</option>
//                         <option value="Guyana">Guyana</option>
//                         <option value="Haiti">Haiti</option>
//                         <option value="Heard Island and Mcdonald Islands">
//                           Heard Island and Mcdonald Islands
//                         </option>
//                         <option value="Holy See (Vatican City State)">
//                           Holy See (Vatican City State)
//                         </option>
//                         <option value="Honduras">Honduras</option>
//                         <option value="Hong Kong">Hong Kong</option>
//                         <option value="Hungary">Hungary</option>
//                         <option value="Iceland">Iceland</option>
//                         <option value="India">India</option>
//                         <option value="Indonesia">Indonesia</option>
//                         <option value="Iran, Islamic Republic of">
//                           Iran, Islamic Republic of
//                         </option>
//                         <option value="Iraq">Iraq</option>
//                         <option value="Ireland">Ireland</option>
//                         <option value="Isle of Man">Isle of Man</option>
//                         <option value="Israel">Israel</option>
//                         <option value="Italy">Italy</option>
//                         <option value="Jamaica">Jamaica</option>
//                         <option value="Japan">Japan</option>
//                         <option value="Jersey">Jersey</option>
//                         <option value="Jordan">Jordan</option>
//                         <option value="Kazakhstan">Kazakhstan</option>
//                         <option value="Kenya">Kenya</option>
//                         <option value="Kiribati">Kiribati</option>
//                         <option value="Korea, Democratic People's Republic of">
//                           Korea, Democratic People's Republic of
//                         </option>
//                         <option value="Korea, Republic of">
//                           Korea, Republic of
//                         </option>
//                         <option value="Kuwait">Kuwait</option>
//                         <option value="Kyrgyzstan">Kyrgyzstan</option>
//                         <option value="Lao People's Democratic Republic">
//                           Lao People's Democratic Republic
//                         </option>
//                         <option value="Latvia">Latvia</option>
//                         <option value="Lebanon">Lebanon</option>
//                         <option value="Lesotho">Lesotho</option>
//                         <option value="Liberia">Liberia</option>
//                         <option value="Libyan Arab Jamahiriya">
//                           Libyan Arab Jamahiriya
//                         </option>
//                         <option value="Liechtenstein">Liechtenstein</option>
//                         <option value="Lithuania">Lithuania</option>
//                         <option value="Luxembourg">Luxembourg</option>
//                         <option value="Macao">Macao</option>
//                         <option value="Macedonia, The Former Yugoslav Republic of">
//                           Macedonia, The Former Yugoslav Republic of
//                         </option>
//                         <option value="Madagascar">Madagascar</option>
//                         <option value="Malawi">Malawi</option>
//                         <option value="Malaysia">Malaysia</option>
//                         <option value="Maldives">Maldives</option>
//                         <option value="Mali">Mali</option>
//                         <option value="Malta">Malta</option>
//                         <option value="Marshall Islands">
//                           Marshall Islands
//                         </option>
//                         <option value="Martinique">Martinique</option>
//                         <option value="Mauritania">Mauritania</option>
//                         <option value="Mauritius">Mauritius</option>
//                         <option value="Mayotte">Mayotte</option>
//                         <option value="Mexico">Mexico</option>
//                         <option value="Micronesia, Federated States of">
//                           Micronesia, Federated States of
//                         </option>
//                         <option value="Moldova, Republic of">
//                           Moldova, Republic of
//                         </option>
//                         <option value="Monaco">Monaco</option>
//                         <option value="Mongolia">Mongolia</option>
//                         <option value="Montenegro">Montenegro</option>
//                         <option value="Montserrat">Montserrat</option>
//                         <option value="Morocco">Morocco</option>
//                         <option value="Mozambique">Mozambique</option>
//                         <option value="Myanmar">Myanmar</option>
//                         <option value="Namibia">Namibia</option>
//                         <option value="Nauru">Nauru</option>
//                         <option value="Nepal">Nepal</option>
//                         <option value="Netherlands">Netherlands</option>
//                         <option value="New Caledonia">New Caledonia</option>
//                         <option value="New Zealand">New Zealand</option>
//                         <option value="Nicaragua">Nicaragua</option>
//                         <option value="Niger">Niger</option>
//                         <option value="Nigeria">Nigeria</option>
//                         <option value="Niue">Niue</option>
//                         <option value="Norfolk Island">Norfolk Island</option>
//                         <option value="Northern Mariana Islands">
//                           Northern Mariana Islands
//                         </option>
//                         <option value="Norway">Norway</option>
//                         <option value="Oman">Oman</option>
//                         <option value="Pakistan">Pakistan</option>
//                         <option value="Palau">Palau</option>
//                         <option value="Palestinian Territory, Occupied">
//                           Palestinian Territory, Occupied
//                         </option>
//                         <option value="Panama">Panama</option>
//                         <option value="Papua New Guinea">
//                           Papua New Guinea
//                         </option>
//                         <option value="Paraguay">Paraguay</option>
//                         <option value="Peru">Peru</option>
//                         <option value="Philippines">Philippines</option>
//                         <option value="Pitcairn">Pitcairn</option>
//                         <option value="Poland">Poland</option>
//                         <option value="Portugal">Portugal</option>
//                         <option value="Puerto Rico">Puerto Rico</option>
//                         <option value="Qatar">Qatar</option>
//                         <option value="Reunion">Reunion</option>
//                         <option value="Romania">Romania</option>
//                         <option value="Russian Federation">
//                           Russian Federation
//                         </option>
//                         <option value="Rwanda">Rwanda</option>
//                         <option value="Saint Helena">Saint Helena</option>
//                         <option value="Saint Kitts and Nevis">
//                           Saint Kitts and Nevis
//                         </option>
//                         <option value="Saint Lucia">Saint Lucia</option>
//                         <option value="Saint Pierre and Miquelon">
//                           Saint Pierre and Miquelon
//                         </option>
//                         <option value="Saint Vincent and The Grenadines">
//                           Saint Vincent and The Grenadines
//                         </option>
//                         <option value="Samoa">Samoa</option>
//                         <option value="San Marino">San Marino</option>
//                         <option value="Sao Tome and Principe">
//                           Sao Tome and Principe
//                         </option>
//                         <option value="Saudi Arabia">Saudi Arabia</option>
//                         <option value="Senegal">Senegal</option>
//                         <option value="Serbia">Serbia</option>
//                         <option value="Seychelles">Seychelles</option>
//                         <option value="Sierra Leone">Sierra Leone</option>
//                         <option value="Singapore">Singapore</option>
//                         <option value="Slovakia">Slovakia</option>
//                         <option value="Slovenia">Slovenia</option>
//                         <option value="Solomon Islands">Solomon Islands</option>
//                         <option value="Somalia">Somalia</option>
//                         <option value="South Africa">South Africa</option>
//                         <option value="South Georgia and The South Sandwich Islands">
//                           South Georgia and The South Sandwich Islands
//                         </option>
//                         <option value="Spain">Spain</option>
//                         <option value="Sri Lanka">Sri Lanka</option>
//                         <option value="Sudan">Sudan</option>
//                         <option value="Suriname">Suriname</option>
//                         <option value="Svalbard and Jan Mayen">
//                           Svalbard and Jan Mayen
//                         </option>
//                         <option value="Swaziland">Swaziland</option>
//                         <option value="Sweden">Sweden</option>
//                         <option value="Switzerland">Switzerland</option>
//                         <option value="Syrian Arab Republic">
//                           Syrian Arab Republic
//                         </option>
//                         <option value="Taiwan, Province of China">
//                           Taiwan, Province of China
//                         </option>
//                         <option value="Tajikistan">Tajikistan</option>
//                         <option value="Tanzania, United Republic of">
//                           Tanzania, United Republic of
//                         </option>
//                         <option value="Thailand">Thailand</option>
//                         <option value="Timor-leste">Timor-leste</option>
//                         <option value="Togo">Togo</option>
//                         <option value="Tokelau">Tokelau</option>
//                         <option value="Tonga">Tonga</option>
//                         <option value="Trinidad and Tobago">
//                           Trinidad and Tobago
//                         </option>
//                         <option value="Tunisia">Tunisia</option>
//                         <option value="Turkey">Turkey</option>
//                         <option value="Turkmenistan">Turkmenistan</option>
//                         <option value="Turks and Caicos Islands">
//                           Turks and Caicos Islands
//                         </option>
//                         <option value="Tuvalu">Tuvalu</option>
//                         <option value="Uganda">Uganda</option>
//                         <option value="Ukraine">Ukraine</option>
//                         <option value="United Arab Emirates">
//                           United Arab Emirates
//                         </option>
//                         <option value="United Kingdom">United Kingdom</option>
//                         <option value="United States">United States</option>
//                         <option value="Uruguay">Uruguay</option>
//                         <option value="Uzbekistan">Uzbekistan</option>
//                         <option value="Vanuatu">Vanuatu</option>
//                         <option value="Venezuela">Venezuela</option>
//                         <option value="Viet Nam">Viet Nam</option>
//                         <option value="Western Sahara">Western Sahara</option>
//                         <option value="Yemen">Yemen</option>
//                         <option value="Zambia">Zambia</option>
//                         <option value="Zimbabwe">Zimbabwe</option>
//                       </select>
//                     </div>
//                   )}
//                   <button type="submit">
//                     {userType === "employer"
//                       ? "Proceed to Payment"
//                       : "Register"}
//                   </button>
//                 </form>
//               ) : (
//                 // Payment Step UI, based on `paymentData`
//                 <div>
//                   <h4>Proceed with Payment</h4>
//                   {/* <form action={paymentData.action} method="post">
//                   <input type="hidden" name="key" value={paymentData.key} />
//                   <input type="hidden" name="hash" value={paymentData.hash} />
//                   <input type="hidden" name="txnid" value={paymentData.txnid} />
//                   <button type="submit">Pay Now</button>
//                 </form> */}

//                   {isPaymentStep && (
//                     // <form action={paymentData.action} method="POST">
//                     //   <input type="hidden" name="key" value={paymentData.key} />
//                     //   <input
//                     //     type="hidden"
//                     //     name="hash"
//                     //     value={paymentData.hash}
//                     //   />
//                     //   <input
//                     //     type="hidden"
//                     //     name="txnid"
//                     //     value={paymentData.txnid}
//                     //   />
//                     //   <input
//                     //     type="hidden"
//                     //     name="amount"
//                     //     value={paymentData.amount || "1.00"}
//                     //   />
//                     //   <input
//                     //     type="hidden"
//                     //     name="firstname"
//                     //     value={formData.firstname || ""}
//                     //   />
//                     //   <input
//                     //     type="hidden"
//                     //     name="email"
//                     //     value={formData.email || ""}
//                     //   />
//                     //   {/* Additional hidden fields for payment */}
//                     //   <button type="submit">Pay Now</button>
//                     // </form>

// <form action={paymentData.action} method="POST">
// <input type="hidden" name="key" value={paymentData.key} />
// <input type="hidden" name="hash" value={paymentData.hash} />
// <input type="hidden" name="txnid" value={paymentData.txnid} />
// <input type="hidden" name="amount" value={paymentData.amount} />
// <input type="hidden" name="productinfo" value="Employer Registration" />
// <input type="hidden" name="firstname" value={formData.name} />
// <input type="hidden" name="email" value={formData.email} />
// <button type="submit">Pay Now</button>
// </form>
//                   )}
//                 </div>
//               )}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
}

export default SignUp;
